import {
  Box,
  Wrap,
  WrapItem,
  Image,
  Container,
  Heading,
  VStack,
  Text,
  Flex,
  UnorderedList,
  ListItem,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import Layout from '../../../components/Layout';
import { ProjectsSection } from '../../../components/sections/ProjectsSlider';
import SEO from '../../../components/seo';
import { ContactUsBannerWithForm, Hero, SubHero } from '../../../components/shared';
import { useCapitalProjectsService } from '../../../GraphQL/Queries/useCapitalProjectsService';

const CapitalProjects = ({ location }) => {
  const data = useCapitalProjectsService();

  const {
    acf_service_info,
    acf_text_block_title,
    acf_image_text_block,
    acf_projects_slider,
    acf_quote,
  } = data.serviceBy;

  const [isMobile] = useMediaQuery('(max-width: 700px)');

  return (
    <Layout location={location}>
      <SEO title="Capital Projects" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />

      <Container my={{ base: '3rem', lg: '10rem' }} mb={{ base: '1rem', md: '3rem' }} overflow="hidden">
        <Wrap justify={{ lg: 'space-between' }}>
          <WrapItem flex="1" minW={{ base: '300px', lg: '500px' }} px={{ base: '2rem', lg: 'unset' }}>
            <VStack align="flex-start" spacing="1rem" pr={{ lg: '3rem' }}>
              <Heading fontSize="35px" color="primary">
                {acf_image_text_block.blockList[0].title}
              </Heading>
              <Text textStyle="p">
                As the leading general construction company, we ensure your project’s completion by tailoring our skill
                set and knowledge to your needs. We combine innovative tools and a unique, strategic approach to
                minimize potential risks, produce optimal performance, and facilitate your capital project’s success.
                Our team specializes in a wide array of capital project services that focus on providing and increasing
                value to your commercial property. For example:
                <UnorderedList my="1rem">
                  <ListItem textStyle="p" my="3">
                    Parks
                  </ListItem>
                  <ListItem textStyle="p" my="3">
                    Community pools
                  </ListItem>
                  <ListItem textStyle="p" my="3">
                    Structural improvements to buildings
                  </ListItem>
                </UnorderedList>
                We provide end-to-end services that combine industry-leading practices and in-depth expertise to every
                commercial project we embark on. As part of our commitment to client satisfaction, we prioritize speedy
                delivery without hindering quality.
              </Text>
            </VStack>
          </WrapItem>
          <WrapItem flex="1" minW={{ base: '300px', lg: '500px' }} pl={{ lg: '3rem' }} h={{ lg: '618px' }}>
            <Image
              src={isMobile ? '/images/capital-overlay2.jpg' : '/images/apartment-pool.jpg'}
              h="100%"
              w="100%"
              objectFit="cover"
              objectPosition={{ md: 'right' }}
              mt={{ base: '2rem', lg: 'unset' }}
            />
          </WrapItem>
        </Wrap>
      </Container>

      <Flex maxW="1440px" w="100%" margin={{ lg: '-10rem auto 10rem auto' }}>
        <Box flex={{ base: '0.7', lg: '1.2' }} h={{ lg: '699px' }}>
          <Image
            src={isMobile ? '/images/apartment-pool.jpg' : '/images/capital-overlay2.jpg'}
            objectFit="cover"
            h={{ base: '100%', lg: '100%' }}
            w="100%"
            pr={{ base: '1rem', lg: 'unset' }}
            pl={{ lg: '2rem' }}
          />
        </Box>

        <Box flex={{ base: '1', lg: '1' }} position="relative">
          <Image
            src="/images/park-top.jpg"
            objectFit="cover"
            h={{ base: '100%', lg: '90%' }}
            w={{ base: '100%', lg: '100%' }}
            pos={{ lg: 'absolute' }}
            top={{ lg: '-3rem' }}
            left={{ lg: '-5rem' }}
          />
        </Box>
      </Flex>

      {/* { acf_image_text_block.blockList.map( (item, index) => (
        (item.blockStyle === 'Blue background') ? 
        <MainServiceBanner
        image={item.image.sourceUrl}
        title={item.title}
        noCta
        description={item.content.replace(/<\/?[^>]+(>|$)/g, "")}
        />
        :
        <>
        <Container my={{ base: '3rem', lg: '10rem' }} mb={{ base: '1rem', md: '3rem' }} overflow="hidden">
        <Wrap justify={{ lg: 'space-between' }}>
          <WrapItem flex="1" minW={{ base: '300px', lg: '500px' }} px={{ base: '2rem', lg: 'unset' }}>
            <VStack align="flex-start" spacing="1rem" pr={{ lg: '3rem' }}>
              <Heading fontSize="35px" color="primary">
                {item.title}
              </Heading>
              <Text textStyle="p">
                {item.content}
              </Text>
            </VStack>
          </WrapItem>
          <WrapItem flex="1" minW={{ base: '300px', lg: '500px' }} pl={{ lg: '3rem' }} h={{ lg: '618px' }}>
            <Image
              src={isMobile ? item.galleryImages[1].sourceUrl : '/images/apartment-pool.jpg'}
              h="100%"
              w="100%"
              objectFit="cover"
              objectPosition={{ md: 'right' }}
              mt={{ base: '2rem', lg: 'unset' }}
            />
          </WrapItem>
        </Wrap>
      </Container>
      <Flex maxW="1440px" w="100%" margin={{ lg: '-10rem auto 10rem auto' }}>
        <Box flex={{ base: '0.7', lg: '1.2' }} h={{ lg: '699px' }}>
          <Image
            src={isMobile ? item.image.sourceUrl : item.galleryImages[1].sourceUrl}
            objectFit="cover"
            h={{ base: '100%', lg: '100%' }}
            w="100%"
            pr={{ base: '1rem', lg: 'unset' }}
            pl={{ lg: '2rem' }}
          />
        </Box>

        <Box flex={{ base: '1', lg: '1' }} position="relative">
          <Image
            src={item.galleryImages[0].sourceUrl}
            objectFit="cover"
            h={{ base: '100%', lg: '90%' }}
            w={{ base: '100%', lg: '100%' }}
            pos={{ lg: 'absolute' }}
            top={{ lg: '-3rem' }}
            left={{ lg: '-5rem' }}
          />
        </Box>
      </Flex>
        </>
      ) ) } */}

      <Box mt={{ base: '3rem', md: '-5rem' }} />
      <ProjectsSection projects={acf_projects_slider.projectList} />
      <Box mb={{ md: '20rem' }}></Box>

      <ContactUsBannerWithForm
        isCommercial
        lightBackground
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default CapitalProjects;
