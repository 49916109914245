import { graphql, useStaticQuery } from 'gatsby';

function useCapitalProjectsService() {
  const data = useStaticQuery(
    graphql`
      query CapitalProjectsServiceQuery {
        wpgraphql {
          serviceBy(serviceId: 53) {
            acf_service_info {
              serviceTitle
              serviceShortDescription
              serviceUrl
              icon {
                sourceUrl
              }
              background {
                sourceUrl
              }
            }
            acf_text_block_title {
              title
              content
              inlineRow
            }
            acf_image_text_block {
              blockList {
                blockStyle
                title
                content
                isInverted
                image {
                  sourceUrl
                }
                galleryImages {
                  sourceUrl
                }
              }
            }
            acf_projects_slider {
              projectList {
                projectName
                projectLocation
                projectGallery {
                  sourceUrl
                }
                mainImage {
                  sourceUrl
                }
              }
            }
            acf_quote {
              titleQuoteSection
              descriptionQuote
              phoneNumber
              backgroundImage {
                sourceUrl
              }
              iconQuote {
                sourceUrl
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useCapitalProjectsService };
